const language = {
  lang: "hu",
  name: "Magyar",
  flag: process.env.BASE_URL + "media/svg/flags/115-hungary.svg",
};

const AllLanguages = [
  {
    lang: "hu",
    name: "Magyar",
    flag: process.env.BASE_URL + "media/svg/flags/115-hungary.svg",
  },
  {
    lang: "en",
    name: "English",
    flag: process.env.BASE_URL + "media/svg/flags/226-united-states.svg",
  },
  {
    lang: "ro",
    name: "Română",
    flag: process.env.BASE_URL + "media/svg/flags/109-romania.svg",
  },

  {
    lang: "ch",
    name: "Mandarin",
    flag: process.env.BASE_URL + "media/svg/flags/034-china.svg",
  },
  {
    lang: "es",
    name: "Spanish",
    flag: process.env.BASE_URL + "media/svg/flags/128-spain.svg",
  },
  {
    lang: "jp",
    name: "Japanese",
    flag: process.env.BASE_URL + "media/svg/flags/063-japan.svg",
  },
  {
    lang: "de",
    name: "German",
    flag: process.env.BASE_URL + "media/svg/flags/162-germany.svg",
  },
  {
    lang: "fr",
    name: "French",
    flag: process.env.BASE_URL + "media/svg/flags/195-france.svg",
  },
];

const i18nService = {
  defaultLanguage: "hu",
  defaultUserLanguage: window.localStorage.getItem("defaultLanguage") || "hu",
  defaultLanguages: [
    {
      lang: "hu",
      name: "Magyar",
      flag: process.env.BASE_URL + "media/svg/flags/115-hungary.svg",
    },
    {
      lang: "en",
      name: "English",
      flag: process.env.BASE_URL + "media/svg/flags/226-united-states.svg",
    },
    {
      lang: "de",
      name: "German",
      flag: process.env.BASE_URL + "media/svg/flags/162-germany.svg",
    },
  ],

  userLanguages: window.localStorage.getItem("userLocales")
    ? JSON.parse(window.localStorage.getItem("userLocales")).map((item) => {
        return AllLanguages.find((lang) => lang.lang == item);
      })
    : [language],

  languages: [
    {
      lang: "hu",
      name: "Magyar",
      flag: process.env.BASE_URL + "media/svg/flags/115-hungary.svg",
    },
    {
      lang: "en",
      name: "English",
      flag: process.env.BASE_URL + "media/svg/flags/226-united-states.svg",
    },
    {
      lang: "de",
      name: "German",
      flag: process.env.BASE_URL + "media/svg/flags/162-germany.svg",
    },
  ],

  /**
   * Keep the active language in the localStorage
   * @param lang
   */
  setActiveLanguage(lang) {
    window.localStorage.removeItem("language");
    window.localStorage.setItem("language", lang);
  },

  /**
   * Get the current active language
   * @returns {string | string}
   */
  getActiveLanguage() {
    return window.localStorage.getItem("language") || this.defaultLanguage;
  },

  setUserActiveLanguage(lang) {
    window.localStorage.removeItem("userLanguage");
    window.localStorage.setItem("userLanguage", lang);
  },

  /**
   * Get the current active language
   * @returns {string | string}
   */
  getUserActiveLanguage() {
    return (
      window.localStorage.getItem("userLanguage") || this.defaultUserLanguage
    );
  },

  setSiteLocals(locales) {
    if (locales && locales.length > 0) {
      window.localStorage.removeItem("defaultLanguage");
      window.localStorage.setItem("defaultLanguage", locales[0]);
      window.localStorage.removeItem("userLocales");
      window.localStorage.setItem("userLocales", JSON.stringify(locales));
      window.localStorage.removeItem("userLanguage");
    }
  },
};

export default i18nService;
